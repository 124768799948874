import { default as artportfoliooQ81HctK7UMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/artportfolio.vue?macro=true";
import { default as crudcZmDgbBaHmMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/crud.vue?macro=true";
import { default as examplebHTC8HyOJRMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/example.vue?macro=true";
import { default as indexaz70NwWljQMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/index.vue?macro=true";
import { default as laVistatwCAFRx44sMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/laVista.vue?macro=true";
import { default as laVista2sveVzlvwdbMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/laVista2.vue?macro=true";
import { default as locationOMqWDwSxNfMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/location.vue?macro=true";
import { default as personal_45timelinewR8EZedMQ2Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/personal-timeline.vue?macro=true";
import { default as slugmaybegXhvIYSJLiMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/slugmaybe.vue?macro=true";
import { default as colorslsCp51ZKJcMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/colors.vue?macro=true";
import { default as contactEmENrhbt0CMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/contact.vue?macro=true";
import { default as donateurPPqxReefMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/donate.vue?macro=true";
import { default as index46bGi0fFcLMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/goals/index.vue?macro=true";
import { default as index852SWMnYQ6Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/index.vue?macro=true";
import { default as investw0q4dmnYM1Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/invest.vue?macro=true";
import { default as applicationQCpqoQcyJMMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/investors/application.vue?macro=true";
import { default as indextQocqiSyrTMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/investors/index.vue?macro=true";
import { default as portfolio8VijWZyiIpMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/investors/portfolio.vue?macro=true";
import { default as disclosureskIoZrNLC1bMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/disclosures.vue?macro=true";
import { default as faqs40lBpDyg5DMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/faqs.vue?macro=true";
import { default as indexLJJhJkVPmuMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/index.vue?macro=true";
import { default as privacyPolicyer7tKJe3oOMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/privacyPolicy.vue?macro=true";
import { default as termsAndConditionsQ6LichsXjpMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/termsAndConditions.vue?macro=true";
import { default as michaelstrainA1kDED3p5HMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/michaelstrain.vue?macro=true";
import { default as newlandingTCfTXA5WgoMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/newlanding.vue?macro=true";
import { default as project_45proposalwYUofxVpC6Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/project-proposal.vue?macro=true";
import { default as indextXeeeGwfblMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/projects/index.vue?macro=true";
import { default as activetesthN97WhnIVlMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/reference/activetest.vue?macro=true";
import { default as pretty_45testZnkvEf6gOSMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/reference/pretty-test.vue?macro=true";
import { default as testNS2auuL8wzMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/reference/test.vue?macro=true";
import { default as schedulerfaAxV3uY8zMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/scheduler.vue?macro=true";
import { default as seller_45applicationHGFKifF88BMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/seller-application.vue?macro=true";
import { default as accountingzpIgX07Z5AMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/accounting.vue?macro=true";
import { default as consultinghFFGZUAJh1Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/consulting.vue?macro=true";
import { default as digitalMarketingrAEbUUZ8twMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/digitalMarketing.vue?macro=true";
import { default as fulfillmentK4jZI5aJzZMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/fulfillment.vue?macro=true";
import { default as index1PjIDUhmoKMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/index.vue?macro=true";
import { default as legalfojZIvBQNjMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/legal.vue?macro=true";
import { default as indexh0KDjMNrkvMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/callCenter/index.vue?macro=true";
import { default as customProductsd18PTEbN3XMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/customProducts.vue?macro=true";
import { default as digitalPrintskDHA1BBZJJMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/digitalPrints.vue?macro=true";
import { default as graphicDesigny50sVCnyLCMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/graphicDesign.vue?macro=true";
import { default as indexEEjuh9axTdMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/index.vue?macro=true";
import { default as physicalPaintingsObb2UCoMTUMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/physicalPaintings.vue?macro=true";
import { default as authorshipiszWVTTPRnMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/authorship.vue?macro=true";
import { default as indexoBNiQqYrkeMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/index.vue?macro=true";
import { default as ecommercemsZrgToiSnMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/ecommerce.vue?macro=true";
import { default as indexIparEEd5ytMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/index.vue?macro=true";
import { default as flatbedHomes8s6mYMMTKTMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/flatbedHomes.vue?macro=true";
import { default as handymanO6YES31G1vMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/handyman.vue?macro=true";
import { default as homeCare99hnaLbEEuMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/homeCare.vue?macro=true";
import { default as indexUzuQ0D2nw0Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/index.vue?macro=true";
import { default as landscapingZsuNzs5UpUMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/landscaping.vue?macro=true";
import { default as lexingtonMAII65In8nMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/lexington.vue?macro=true";
import { default as managementServicesXGxCVFsuWyMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/managementServices.vue?macro=true";
import { default as panamaWorkforceHousingZf2J5OHHjXMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/panamaWorkforceHousing.vue?macro=true";
import { default as pineKeybcul3QDohTMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/pineKey.vue?macro=true";
import { default as sustainableHousingYA0pYM7nuhMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/sustainableHousing.vue?macro=true";
import { default as socialMediaMarketing6TWrBC2CubMeta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/socialMediaMarketing.vue?macro=true";
import { default as webDevelopment5wqFYJ5ge0Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/webDevelopment.vue?macro=true";
import { default as sponsorn62abSjF06Meta } from "C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/sponsor.vue?macro=true";
export default [
  {
    name: "artportfolio",
    path: "/artportfolio",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/artportfolio.vue").then(m => m.default || m)
  },
  {
    name: "blog-crud",
    path: "/blog/crud",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/crud.vue").then(m => m.default || m)
  },
  {
    name: "blog-example",
    path: "/blog/example",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/example.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-laVista",
    path: "/blog/laVista",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/laVista.vue").then(m => m.default || m)
  },
  {
    name: "blog-laVista2",
    path: "/blog/laVista2",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/laVista2.vue").then(m => m.default || m)
  },
  {
    name: "blog-location",
    path: "/blog/location",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/location.vue").then(m => m.default || m)
  },
  {
    name: "blog-personal-timeline",
    path: "/blog/personal-timeline",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/personal-timeline.vue").then(m => m.default || m)
  },
  {
    name: "blog-slugmaybe",
    path: "/blog/slugmaybe",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/blog/slugmaybe.vue").then(m => m.default || m)
  },
  {
    name: "colors",
    path: "/colors",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/colors.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/donate.vue").then(m => m.default || m)
  },
  {
    name: "goals",
    path: "/goals",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/goals/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invest",
    path: "/invest",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/invest.vue").then(m => m.default || m)
  },
  {
    name: "investors-application",
    path: "/investors/application",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/investors/application.vue").then(m => m.default || m)
  },
  {
    name: "investors",
    path: "/investors",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/investors/index.vue").then(m => m.default || m)
  },
  {
    name: "investors-portfolio",
    path: "/investors/portfolio",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/investors/portfolio.vue").then(m => m.default || m)
  },
  {
    name: "legal-disclosures",
    path: "/legal/disclosures",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/disclosures.vue").then(m => m.default || m)
  },
  {
    name: "legal-faqs",
    path: "/legal/faqs",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/faqs.vue").then(m => m.default || m)
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-privacyPolicy",
    path: "/legal/privacyPolicy",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: "legal-termsAndConditions",
    path: "/legal/termsAndConditions",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/legal/termsAndConditions.vue").then(m => m.default || m)
  },
  {
    name: "michaelstrain",
    path: "/michaelstrain",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/michaelstrain.vue").then(m => m.default || m)
  },
  {
    name: "newlanding",
    path: "/newlanding",
    meta: newlandingTCfTXA5WgoMeta || {},
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/newlanding.vue").then(m => m.default || m)
  },
  {
    name: "project-proposal",
    path: "/project-proposal",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/project-proposal.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "reference-activetest",
    path: "/reference/activetest",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/reference/activetest.vue").then(m => m.default || m)
  },
  {
    name: "reference-pretty-test",
    path: "/reference/pretty-test",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/reference/pretty-test.vue").then(m => m.default || m)
  },
  {
    name: "reference-test",
    path: "/reference/test",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/reference/test.vue").then(m => m.default || m)
  },
  {
    name: "scheduler",
    path: "/scheduler",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/scheduler.vue").then(m => m.default || m)
  },
  {
    name: "seller-application",
    path: "/seller-application",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/seller-application.vue").then(m => m.default || m)
  },
  {
    name: "services-business-accounting",
    path: "/services/business/accounting",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/accounting.vue").then(m => m.default || m)
  },
  {
    name: "services-business-consulting",
    path: "/services/business/consulting",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/consulting.vue").then(m => m.default || m)
  },
  {
    name: "services-business-digitalMarketing",
    path: "/services/business/digitalMarketing",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/digitalMarketing.vue").then(m => m.default || m)
  },
  {
    name: "services-business-fulfillment",
    path: "/services/business/fulfillment",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/fulfillment.vue").then(m => m.default || m)
  },
  {
    name: "services-business",
    path: "/services/business",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/index.vue").then(m => m.default || m)
  },
  {
    name: "services-business-legal",
    path: "/services/business/legal",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/business/legal.vue").then(m => m.default || m)
  },
  {
    name: "services-callCenter",
    path: "/services/callCenter",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/callCenter/index.vue").then(m => m.default || m)
  },
  {
    name: "services-creative-art-customProducts",
    path: "/services/creative/art/customProducts",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/customProducts.vue").then(m => m.default || m)
  },
  {
    name: "services-creative-art-digitalPrints",
    path: "/services/creative/art/digitalPrints",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/digitalPrints.vue").then(m => m.default || m)
  },
  {
    name: "services-creative-art-graphicDesign",
    path: "/services/creative/art/graphicDesign",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/graphicDesign.vue").then(m => m.default || m)
  },
  {
    name: "services-creative-art",
    path: "/services/creative/art",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/index.vue").then(m => m.default || m)
  },
  {
    name: "services-creative-art-physicalPaintings",
    path: "/services/creative/art/physicalPaintings",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/art/physicalPaintings.vue").then(m => m.default || m)
  },
  {
    name: "services-creative-authorship",
    path: "/services/creative/authorship",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/authorship.vue").then(m => m.default || m)
  },
  {
    name: "services-creative",
    path: "/services/creative",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/creative/index.vue").then(m => m.default || m)
  },
  {
    name: "services-ecommerce",
    path: "/services/ecommerce",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-flatbedHomes",
    path: "/services/realEstate/flatbedHomes",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/flatbedHomes.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-handyman",
    path: "/services/realEstate/handyman",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/handyman.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-homeCare",
    path: "/services/realEstate/homeCare",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/homeCare.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate",
    path: "/services/realEstate",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/index.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-landscaping",
    path: "/services/realEstate/landscaping",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/landscaping.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-lexington",
    path: "/services/realEstate/lexington",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/lexington.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-managementServices",
    path: "/services/realEstate/managementServices",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/managementServices.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-panamaWorkforceHousing",
    path: "/services/realEstate/panamaWorkforceHousing",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/panamaWorkforceHousing.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-pineKey",
    path: "/services/realEstate/pineKey",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/pineKey.vue").then(m => m.default || m)
  },
  {
    name: "services-realEstate-sustainableHousing",
    path: "/services/realEstate/sustainableHousing",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/realEstate/sustainableHousing.vue").then(m => m.default || m)
  },
  {
    name: "services-socialMediaMarketing",
    path: "/services/socialMediaMarketing",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/socialMediaMarketing.vue").then(m => m.default || m)
  },
  {
    name: "services-webDevelopment",
    path: "/services/webDevelopment",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/services/webDevelopment.vue").then(m => m.default || m)
  },
  {
    name: "sponsor",
    path: "/sponsor",
    component: () => import("C:/Users/Administrator/Desktop/michael-strain/michael-strain/pages/sponsor.vue").then(m => m.default || m)
  }
]